<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="flex align-center">
        <div
          class="font-20 mr-15 cursorp"
          :class="toptype == 1 ? 'font-weight-bolder text-FE5A09' : 'text-333333'"
          @click="cuttoptype(1)"
        >
          充值
        </div>
        <div
          class="font-20 cursorp"
          :class="toptype == 2 ? 'font-weight-bolder text-FE5A09' : 'text-333333'"
          @click="cuttoptype(2)"
        >
          会员
        </div>
      </div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/myPurse' }">我的钱包</el-breadcrumb-item>
          <el-breadcrumb-item v-if="toptype == 1">充值</el-breadcrumb-item>
          <el-breadcrumb-item v-else>会员</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div class="py-35">
      <div class="purseBox_cont" v-if="toptype == 1">
        <div class="purseBox_cont_input mb-25">
          <el-input v-model="input" placeholder="输入充值金额" type="number">
            <div slot="prefix" class="font-14 text-555555">￥</div>
          </el-input>
        </div>
        <div class="flex align-center mb-35">
          <div class="mr-20">充值方式</div>
          <!-- <div
            :class="type == 1 ? 'purseBox_cont_typeact' : 'purseBox_cont_type'"
            @click="cuttype(1)"
          >
            <img
              src="@/assets/img/weixin.png"
              class="mr-10"
              width="1.625rem"
              height="1.625rem"
              alt=""
            />
            微信
          </div> -->
          <div
            :class="type == 3 ? 'purseBox_cont_typeact' : 'purseBox_cont_type'"
            @click="cuttype(3)"
          >
            <!-- <img
              src="@/assets/img/zhufubao.png"
              class="mr-10"
              width="1.625rem"
              height="1.625rem"
              alt=""
            /> -->
            <el-image
              class="mr-10"
              style="width: 1.625rem; height: 1.625rem"
              :src="require('@/assets/img/zhufubao.png')"
              fit="cover"
            ></el-image>
            支付宝
          </div>
        </div>
        <div class="purseBox_cont_btn" @click="submit">提交</div>
      </div>
      <div class="purseBox_cont2" v-else>
        <div class="mb-20">会员套餐</div>
        <ul class="flex align-center purseBox_cont2_ul mb-25">
          <li
            v-for="(item, index) in info.package"
            :key="index"
            class="cursorp"
            :class="vipId == item.id ? 'purseBox_cont2_liact' : 'purseBox_cont2_li'"
            :style="index != 0 ? 'margin-left: 1rem;' : ''"
            @click="cutviptao(item)"
          >
            {{ item.name }}
          </li>
        </ul>
        <div class="flex align-center mb-35">
          <div class="mr-20">充值方式</div>
          <!-- <div
            :class="type == 1 ? 'purseBox_cont_typeact' : 'purseBox_cont_type'"
            @click="cuttype(1)"
          >
            <img
              src="@/assets/img/weixin.png"
              class="mr-10"
              width="1.625rem"
              height="1.625rem"
              alt=""
            />
            微信
          </div> -->
          <div
            :class="type == 3 ? 'purseBox_cont_typeact' : 'purseBox_cont_type'"
            @click="cuttype(3)"
          >
            <!-- <img
              src="@/assets/img/zhufubao.png"
              class="mr-10"
              width="1.625rem"
              height="1.625rem"
              alt=""
            /> -->
            <el-image
              class="mr-10"
              style="width: 1.625rem; height: 1.625rem"
              :src="require('@/assets/img/zhufubao.png')"
              fit="cover"
            ></el-image>
            支付宝
          </div>
        </div>
        <div class="pt-30 border-top border-F1F2F3 flex align-center justify-between">
          <div>
            <div class="text-FF524C mb-15">
              <span>￥</span
              ><span class="font-25 font-weight-bolder">{{ vipprice }}</span>
            </div>
            <div>
              开通前请阅读
              <span class="text-FF524C cursorp" @click="xeiyiflag = true"
                >《会员服务协议》
              </span>
            </div>
          </div>
          <div class="purseBox_cont2_btn cursorp" @click="submit">确认协议并支付</div>
        </div>
      </div>
    </div>
    <el-dialog title="支付" :visible.sync="dialogVisible" width="35%">
      <div class="flex align-center justify-center">
        <!-- <img :src="code" width="31.25rem" height="31.25rem" class="mx-auto" alt="" /> -->
        <el-image
          class="mx-auto"
          style="width: 31.25rem; height: 31.25rem"
          :src="code"
          fit="cover"
        ></el-image>
      </div>
    </el-dialog>
    <el-dialog title="会员服务协议" :visible.sync="xeiyiflag" width="35%">
      <div class="" style="height: 31.25rem; overflow-y: auto">
        <div v-html="info.package_content"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      xeiyiflag: false,
      dialogVisible: false,
      input: "",
      type: 3,
      code: "",
      query_url: "",
      timer: null, //计时器
      toptype: 1,
      info: "",
      vipId: "",
      vipprice: 0,
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let toptype = this.$route.query.toptype;
        if (toptype) {
          this.toptype = toptype;
          this.getVip();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let toptype = this.$route.query.toptype;
    if (toptype) {
      this.toptype = toptype;
      this.getVip();
    }else{
      this.getVip();
    }
  },
  methods: {
    cutviptao(item) {
      this.vipprice = item.price;
      this.vipId = item.id;
    },
    cuttoptype(id) {
      this.toptype = id;
    },
    cuttype(id) {
      this.type = id;
    },
    // obj包括接口参数、接口地址、请求类型
    // successFun成功回调
    // errorFn失败回调
    // flag 同步、异步   true-异步
    httpAjax(obj, successFun, errorFn) {
      var xmlHttp = new XMLHttpRequest();
      var httpMethod = obj.method.toUpperCase();
      var httpUrl = obj.url;
      var requestData = JSON.stringify(obj.data) || {};
      xmlHttp.onreadystatechange = () => {
        //每当 readyState 属性改变时，就会调用该函数
        if (xmlHttp.readyState === 4) {
          //请求成功执行的回调函数
          successFun(xmlHttp.responseText);
        } else {
          errorFn(xmlHttp.responseText);
        }
      };

      //请求接口
      if (httpMethod == "GET") {
        xmlHttp.open("GET", httpUrl, obj.flag);
        xmlHttp.send(requestData);
      } else if (httpMethod == "POST") {
        xmlHttp.open("POST", httpUrl, obj.flag);
        xmlHttp.setRequestHeader("Content-Type", "application/json");
        xmlHttp.send(requestData);
      }
    },

    // 定时监测状态
    paytime() {
      // window.clearInterval(this.timer);
      // 调用方式
      let that = this;
      let params = {
        flag: true, //异步请求
        data: {}, //接口数据
        url: that.query_url,
        method: "get",
      };
      that.httpAjax(
        params,
        function (res) {
          let data = JSON.parse(res);
          if (data.status == "pending") {
            console.log("交易正在处理，请稍后");
          } else if (data.status == "succeeded") {
            that.dialogVisible = false;
            window.clearInterval(that.timer);
            that.$message.success("充值成功");
            that.$router.go(-1);
          } else if (data.status == "failed") {
            that.dialogVisible = false;
            window.clearInterval(that.timer);
            that.$message.error("充值失败");
            that.$router.go(-1);
          }
        },
        function () {
          console.log("返回错误");
        }
      );
    },
    getVip() {
      let that = this;
      req
        .post("center/vipPackage", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          res.data.package_content = res.data.package_content
            .replace(/<img/gi, '<img style="max-width:100%;height:auto;display:block" ')
            .replace(/<section/g, "<div")
            .replace(/\/section>/g, "div>");
          that.info = res.data;
          that.vipId = res.data.package[0].id;
          that.vipprice = res.data.package[0].price;
        })
        .catch((err) => {
          that.$message.error(err.msg);
        });
    },
    // 提交充值
    submit() {
      let that = this;
      if (that.toptype == 1) {
        req
          .post("center/recharge", {
            pay_type: that.type,
            money: that.input,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.code = res.data.code;
            that.query_url = res.data.query_url;
            that.dialogVisible = true;
            that.timer = window.setInterval(() => {
              setTimeout(() => {
                that.paytime();
              }, 0);
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      } else {
        req
          .post("center/buyPackage", {
            pay_type: that.type,
            package_id: that.vipId,
          })
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.code = res.data.code;
            that.query_url = res.data.query_url;
            that.dialogVisible = true;
            that.timer = window.setInterval(() => {
              setTimeout(() => {
                that.paytime();
              }, 0);
            }, 3000);
          })
          .catch((err) => {
            console.log(err);
            that.$message.error(err.msg);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
  &_cont {
    &_btn {
      width: 7.6875rem;
      height: 2.1875rem;
      line-height: 2.1875rem;
      text-align: center;
      font-size: 0.875rem;
      color: #ffffff;
      background: #ff524c;
      border-radius: 1.125rem;
      cursor: pointer;
    }
    &_type {
      width: 8.9375rem;
      height: 3.3125rem;
      background: #ffffff;
      border: 0.0625rem solid #dcdcdc;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666666;
      font-size: 0.875rem;
      margin-right: 0.375rem;
      cursor: pointer;
    }
    &_typeact {
      width: 8.9375rem;
      height: 3.3125rem;
      background: #ffffff;
      color: #333333;
      border: 0.0625rem solid #ef6247;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
      margin-right: 0.375rem;
      cursor: pointer;
    }
    &_input {
      ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      ::v-deep .el-input__inner {
        -webkit-appearance: none;
        background-color: #f1f2f3;
        border-radius: 0;
        border: 0;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 3.8125rem;
        line-height: 3.8125rem;
        outline: 0;
        padding: 0 1.875rem;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        width: 32.25rem;
      }
      ::v-deep .el-input__prefix {
        top: 1.3125rem;
      }
      ::v-deep .el-input__suffix {
        top: 1.3125rem;
        right: 16.25rem;
        cursor: pointer;
      }
    }
  }
  &_cont2 {
    &_li {
      width: 8.75rem;
      height: 4.375rem;
      background-color: #f1f2f3;
      color: #555555;
      line-height: 4.375rem;
      text-align: center;
    }
    &_liact {
      width: 8.75rem;
      height: 4.375rem;
      background-color: #ff524c;
      color: #ffffff;
      line-height: 4.375rem;
      text-align: center;
    }
    &_btn {
      width: 8.75rem;
      height: 2.5rem;
      line-height: 2.5rem;
      text-align: center;
      color: #ffffff;
      border-radius: 1.25rem;
      background-color: #ff524c;
    }
  }
}
</style>
